<template lang="pug">
.main-wrapper
    Dialog(header='Remover Notícia' :visible.sync='dialog.apagar' :modal='true')
        p Deseja remover a notícia?
        .ta-center.my-4
            Button.p-button-danger(label='Remover' @click='remove()')

    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Albuns</b>

    .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push(`/albuns/salvar`)")

    ProgressBar(v-if="waiting || waitingApagar" mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        Panel.datatable(header='Notícias')
            DataTable(:autoLayout='true' :value="list.rows" :paginator="true" :rows="10"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll")
                Column(headerStyle='width: 5%; font-weight: bold' field='id' header='id')

                Column(headerStyle='width: 26.6%;' field='name' header='Nome')

                Column(headerStyle='width: 26.6%;' field='cover_photo' header='Foto')

                //- Column(headerStyle='width: 26.6%;' field='categories.name' header='Categoria')

                Column(headerStyle='width: 15%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Visualizar'"
                                icon="jam jam-document"
                                @click="view(props.data.id)"
                            )
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="edit(props.data.id)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar(props.data.id)"
                            )
</template>

<style lang="scss" scoped>
.dialogApagar {
    .p-progress-spinner {
        width: 29px;
        height: auto;
    }
}
</style>

<script>
import Noticias from '../../middleware/controllers/Noticias'
import ProgressBar from "primevue/progressbar";
import Dialog from 'primevue/dialog'
import DataView from 'primevue/dataview'
import Panel from 'primevue/panel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'

export default {
    name: "PerguntasFrequentes",
    components: { ProgressBar, Dialog, DataView, Panel, DataTable, Column, Button, Tooltip },
    directives: { tooltip: Tooltip },
    data() {
        return {
            waiting: false,
            waitingApagar: false,
            dialog: {
                apagar: false
            },
            idASerApagado: '',
            list: [],
            tokenAPI: '',
        };
    },
    created() {
        //Login na nova API
        Noticias.login().then(result => {
            this.tokenAPI = result;
            this.getList()
        })
    },
    methods: {
        getList() {
            this.waiting = true
            Noticias.getAlbuns(this.tokenAPI)
            .then(response => {
                if(! response) this.list = []
                else {
                    // Ordena a lista pelo id em ordem decrescente
                    response.rows.sort((a, b) => {
                        if(a.id < b.id) return 1
                        else return -1
                    })
                    this.list = response
                }
                this.waiting = false
            })
        },
        dialogApagar(id) {
            this.idASerApagado = id
            this.dialog.apagar = true
        } ,
        remove() {
            this.waiting = true
            Noticias.deleteAlbum(this.idASerApagado, this.tokenAPI).then(response => {
                this.dialog.apagar = false
                if(! response)
                    this.$toast.error('Não foi possível remover a notícia.', { duration: 3000 })
                else {
                    this.getList()
                    this.$toast.success('Album removido com sucesso.', { duration: 3000 })
                }

                this.waiting = false
            })
        },
        view(id) {
            this.$router.push(`/noticias/visualizar/${id}/`)
        },
        edit(id) {
            this.$router.push(`/albuns/salvar/${id}/`)
        }
    },

};
</script>
